var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "fb-page",
    [
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: _vm.title, fixed: "" },
          slot: "header",
        },
        [
          _c("fb-button", {
            attrs: {
              slot: "left",
              icon: "back",
              size: "small",
              type: "primary",
            },
            on: {
              click: function ($event) {
                return _vm.$router.back()
              },
            },
            slot: "left",
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "history-case-content" },
        [
          _vm._l(_vm.content, function (value, key) {
            return _c(
              "div",
              { key: key, staticClass: "catalog-item" },
              [
                Array.isArray(value) && key !== "law_data"
                  ? _c(
                      "div",
                      {
                        staticClass: "info-title",
                        on: {
                          click: function ($event) {
                            return _vm.showContent(value)
                          },
                        },
                      },
                      [
                        _c("label", { staticClass: "title-label" }, [
                          _vm._v(_vm._s(_vm.titleMap[key])),
                        ]),
                        _c("i", { staticClass: "iconfont icon-child-off" }),
                      ]
                    )
                  : _vm._e(),
                _vm._l(value, function (info, infoIndex) {
                  return _c(
                    "div",
                    { key: infoIndex, staticClass: "corresponding-info" },
                    [
                      Object.prototype.toString.call(info) === "[object Object]"
                        ? _c(
                            "div",
                            {
                              staticClass: "info-key",
                              on: {
                                click: function ($event) {
                                  return _vm.showContent(info)
                                },
                              },
                            },
                            [
                              _c("label", { staticClass: "key-title" }, [
                                _vm._v(_vm._s(info.name)),
                              ]),
                              _c("i", {
                                staticClass: "iconfont",
                                class: info.iconClass,
                              }),
                            ]
                          )
                        : _vm._e(),
                      Array.isArray(info.values || info.value)
                        ? _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: info.isShowContent,
                                  expression: "info.isShowContent",
                                },
                              ],
                              staticClass: "info-piece",
                            },
                            _vm._l(
                              info.values || info.value,
                              function (infoValue, valueIndex) {
                                return _c(
                                  "p",
                                  {
                                    key: valueIndex,
                                    staticClass: "info-value",
                                  },
                                  [_vm._v(_vm._s(infoValue))]
                                )
                              }
                            ),
                            0
                          )
                        : _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: info.isShowContent,
                                  expression: "info.isShowContent",
                                },
                              ],
                              staticClass: "info-piece",
                            },
                            [
                              _c("p", { staticClass: "info-value" }, [
                                _vm._v(_vm._s(info.value)),
                              ]),
                            ]
                          ),
                    ]
                  )
                }),
              ],
              2
            )
          }),
          _c(
            "div",
            { staticClass: "catalog-item" },
            [
              _c("div", { staticClass: "info-title" }, [
                _c("label", { staticClass: "title-label" }, [
                  _vm._v("引用法规"),
                ]),
                _c("i", { staticClass: "iconfont icon-child-off" }),
              ]),
              _vm._l(_vm.content.law_data, function (law, lawIndex) {
                return _c("div", { key: lawIndex, staticClass: "info-piece" }, [
                  _c("p", { staticClass: "info-value" }, [_vm._v(_vm._s(law))]),
                ])
              }),
            ],
            2
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }