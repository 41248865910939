<template>
  <fb-page>
    <!-- 头部插槽 -->
    <fb-header slot="header"
               :title="title"
               fixed>
      <fb-button slot="left"
                 icon="back"
                 @click="$router.back()"
                 size="small"
                 type="primary">
      </fb-button>
    </fb-header>
    <div class="history-case-content">
      <div class="catalog-item"
           v-for="(value, key) in content"
           :key="key">
        <div class="info-title"
             v-if="Array.isArray(value) && key !== 'law_data'"
             @click="showContent(value)">
          <label class="title-label">{{ titleMap[key] }}</label>
          <i class="iconfont icon-child-off"></i>
        </div>
        <div class="corresponding-info"
             v-for="(info, infoIndex) in value"
             :key="infoIndex">
          <div class="info-key"
               v-if="Object.prototype.toString.call(info) === '[object Object]'"
               @click="showContent(info)">
            <label class="key-title">{{ info.name }}</label>
            <i class="iconfont"
               :class="info.iconClass"></i>
          </div>
          <div class="info-piece"
               v-show="info.isShowContent"
               v-if="Array.isArray(info.values || info.value)">
            <p class="info-value"
               v-for="(infoValue, valueIndex) in info.values || info.value"
               :key="valueIndex">{{ infoValue }}</p>
          </div>
          <div class="info-piece"
               v-show="info.isShowContent"
               v-else>
            <p class="info-value">{{ info.value }}</p>
          </div>
        </div>
      </div>
      <div class="catalog-item">
        <div class="info-title">
          <label class="title-label">引用法规</label>
          <i class="iconfont icon-child-off"></i>
        </div>
        <div class="info-piece"
             v-for="(law, lawIndex) in content.law_data"
             :key="lawIndex">
          <p class="info-value">{{ law }}</p>
        </div>
      </div>
    </div>
  </fb-page>
</template>

<script>
export default {
  name: 'history-case-verdict-content',
  data () {
    return {
      title: '',
      content: {},
      titleMap: {
        above_datas: '目录',
        basic_data: '基本信息',
        law_data: '引用法规'
      }
    }
  },
  created () {
    const docId = this.$route.params.docId
    this.$axios
      .get('/api/tree/item/index.json', {
        params: {
          origin: 1, // todo 上线后需要改成1
          doc_id: docId // todo 上线后需要换成docId
        }
      })
      .then(res => {
        const content = res.data.result
        Object.keys(content).forEach(catalogItem => {
          if (!Object.is(catalogItem, 'case_name')) {
            content[catalogItem].isShowContent = false
            content[catalogItem].iconClass = 'icon-child-off'
            content[catalogItem].forEach(keyItem => {
              if (
                Object.prototype.toString.call(keyItem) === '[object Object]'
              ) {
                keyItem.isShowContent = false
                keyItem.iconClass = 'icon-child-off'
              }
            })
          }
        })
        this.content = content
      })
  },
  methods: {
    showContent (info) {
      console.log('info', info)

      info.isShowContent = !info.isShowContent
      info.iconClass = info.isShowContent ? 'icon-child-on' : 'icon-child-off'
    }
  }
}
</script>

<style lang="stylus" scoped>
.history-case-content
  width 100%
  height 100%
  padding-top 10px
  padding-bottom 10px
  background-color white
  .catalog-item
    .info-title
      height 30px
      margin-left 20px
      font-size 20px
      .title-label
        line-height 30px
        margin-right 10px
      .iconfont
        color #3FB3F0
    .corresponding-info
      margin-top 5px
      margin-bottom 5px
      .info-key
        font-size 15px
        margin-left 40px
        height 25px
        .key-title
          line-height 25px
          margin-right 10px
        .iconfont
          color #3FB3F0
      .info-piece
        .info-value
          margin-left 20px
          font-size 13px
          color #999999
          text-align justify
          margin-right 20px
    .info-piece
      margin-top 5px
      .info-value
        margin-left 20px
        font-size 13px
        color #999999
        text-align justify
        margin-right 20px
</style>
